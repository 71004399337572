import { graphql } from 'gatsby';
import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import Puzzle, { PuzzleTouch } from 'react-image-puzzle-touch';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Hod from '../src/components/Hod/Hod';
import SEO from '../src/helpers/seo';
import tt from '../src/helpers/translation';
import Layout from '../src/components/Layout/Layout';
import H2 from '../src/components/Titles/TitleH2/TitleH2';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import './ficha_actividades.scss';

export const GatsbyQuery = ({ data, pathContext: { locale } }) => graphql`
  query activities($section: String!, $locale: String!) {
    allActivitiesRichData(filter: { lang: { eq: $locale }, tag: { eq: $section } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
            imgRich
          }
        }
      }
    }
    allActivitiesSeoData(filter: { lang: { eq: $locale }, tag: { eq: $section } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allActivitiesTitleBlock(filter: { lang: { eq: $locale }, tag: { eq: $section } }) {
      edges {
        node {
          title
          description
        }
      }
    }
    allActivitiesBreadcrumbBlock(filter: { lang: { eq: $locale }, tag: { eq: $section } }) {
      edges {
        node {
          name
        }
      }
    }
    allActivitiesContentBlock(filter: { lang: { eq: $locale }, tag: { eq: $section } }) {
      edges {
        node {
          title
          body
          cta
          ctaText
        }
      }
    }
    allActivitiesImageHeader(filter: { lang: { eq: $locale }, tag: { eq: $section } }) {
      edges {
        node {
          alt
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allActivitiesSeoHardcoded(filter: { lang: { eq: $locale }, tag: { eq: $section } }) {
      edges {
        node {
          richmark_text
        }
      }
    }
    allActivitiesImageBlock(filter: { lang: { eq: $locale }, tag: { eq: $section } }) {
      edges {
        node {
          alt
          image
          localImage {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allActivitiesImageMobileBlock(filter: { lang: { eq: $locale }, tag: { eq: $section } }) {
      edges {
        node {
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

class ActividadesFicha extends React.Component {
  state = {};

  render() {
    const titleHome = {
      name: this.props.data.allActivitiesTitleBlock.edges[0].node.title,
      subtitle: this.props.data.allActivitiesTitleBlock.edges[0].node.description,
      subtitleType: 'noborder',
      image: {
        url: this.props.data.allActivitiesImageHeader.edges[0].node.localImage.childImageSharp
          .fluid,
        alt: this.props.data.allActivitiesImageHeader.edges[0].node.alt,
      },
      imageMobile: {
        url: this.props.data.allActivitiesImageMobileBlock.edges[0].node.localImage.childImageSharp
          .fluid,
        alt: this.props.data.allActivitiesImageMobileBlock.edges[0].node.alt,
      },
    };

    const h2Data = {
      title: this.props.data.allActivitiesContentBlock.edges[0].node.title,
    };
    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
        options={this.props.pageContext.options}
      >
        <SEO
          title={this.props.data.allActivitiesSeoData.edges[0].node._0.title}
          description={this.props.data.allActivitiesSeoData.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
          imageRich={this.props.data.allActivitiesRichData.edges[0].node.rich_markup.imgRich}
        />
        <div className="is-fullWidth">
          <Hod
            data={this.props.data.allActivitiesRichData.edges[0].node.rich_markup}
            url={this.props.pageContext.url}
            img={this.props.data.allActivitiesImageHeader.edges[0].node.image}
            hardcoded={
              this.props.data.allActivitiesSeoHardcoded.edges.length &&
              this.props.data.allActivitiesSeoHardcoded.edges[0].node.richmark_text
            }
          />
          <Heimdall data={titleHome} />

          <div className="general-index general-aniversario">
            <div className="easteregg-index">
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allActivitiesBreadcrumbBlock.edges[0].node.name}
                options={this.props.pageContext.options}
              />
              <div className="easteregg-image">
                {this.props.pageContext.section === 'puzles-terrorificos' ||
                this.props.pageContext.section === 'puzle-online' ? undefined : (
                  <Img
                    fluid={
                      this.props.data.allActivitiesImageBlock.edges[0].node.localImage
                        .childImageSharp.fluid
                    }
                  />
                )}
                {this.props.pageContext.section === 'tutuki-splash-virtual' ? (
                  <a
                    target="_blank"
                    className="cta-btn"
                    href="https://www.youtube.com/watch?v=i8stdPjUCBk&amp=&t=2s"
                  >
                    {tt('ver vídeo', this.props.pageContext.locale)}
                  </a>
                ) : null}
                {this.props.pageContext.section === 'siente-pareos-bora-bora' ? (
                  <a
                    target="_blank"
                    className="cta-btn"
                    href="https://www.youtube.com/watch?v=8Unuwm5RhEk&amp=&feature=youtu.be"
                  >
                    {tt('ver vídeo', this.props.pageContext.locale)}
                  </a>
                ) : null}
                {this.props.pageContext.section === 'virtual-tami-tami' ? (
                  <a
                    target="_blank"
                    className="cta-btn"
                    href="https://www.youtube.com/watch?v=8LR-0VvmOGY&feature=youtu.be"
                  >
                    {tt('ver vídeo', this.props.pageContext.locale)}
                  </a>
                ) : null}
                {this.props.pageContext.section === 'shambhala-virtual' ? (
                  <a
                    target="_blank"
                    className="cta-btn"
                    href="https://www.youtube.com/watch?v=lKKykTVXyqE"
                  >
                    {tt('ver vídeo', this.props.pageContext.locale)}
                  </a>
                ) : null}
                {this.props.pageContext.section === 'vive-viva-mexico' ? (
                  <a
                    target="_blank"
                    className="cta-btn"
                    href="https://www.youtube.com/watch?v=KohxWS3JpeE"
                  >
                    {tt('ver vídeo', this.props.pageContext.locale)}
                  </a>
                ) : null}
              </div>
              <H2 data={h2Data} />
              <div
                className="easteregg-text"
                dangerouslySetInnerHTML={{
                  __html: this.props.data.allActivitiesContentBlock.edges[0].node.body,
                }}
              />
              {this.props.pageContext.section === 'puzles-terrorificos' ||
              this.props.pageContext.section === 'puzle-online' ? (
                <div className="puzzle-container">
                  <div className="puzzle-element isDesktop">
                    <Puzzle
                      image={this.props.data.allActivitiesImageBlock.edges[0].node.image}
                      level="5"
                      size="500"
                    />
                  </div>
                  <div className="puzzle-element isMobileNotTouch">
                    <Puzzle
                      image={this.props.data.allActivitiesImageBlock.edges[0].node.image}
                      level="5"
                      size="300"
                    />
                  </div>
                  <div className="puzzle-element isMobile">
                    <PuzzleTouch
                      image={this.props.data.allActivitiesImageBlock.edges[0].node.image}
                      level="5"
                      size="300"
                    />
                  </div>

                  <div className="puzzle-element isDesktop">
                    <Puzzle
                      image={this.props.data.allActivitiesImageBlock.edges[1].node.image}
                      level="5"
                      size="500"
                    />
                  </div>
                  <div className="puzzle-element isMobileNotTouch">
                    <Puzzle
                      image={this.props.data.allActivitiesImageBlock.edges[1].node.image}
                      level="5"
                      size="300"
                    />
                  </div>
                  <div className="puzzle-element isMobile">
                    <PuzzleTouch
                      image={this.props.data.allActivitiesImageBlock.edges[1].node.image}
                      level="5"
                      size="300"
                    />
                  </div>
                </div>
              ) : undefined}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ActividadesFicha;
